<template>
	<div class="justify-content-center">
		<head>
			<meta charset="UTF-8" />
			<title>Insert title here</title>
			<meta name="viewport" content="width=device-width, initial-scale=1" />
			<link rel="profile" href="https://gmpg.org/xfn/11" />
			<link rel="preconnect" href="https://fonts.googleapis.com" />
			<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
			<link
				href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap"
				rel="stylesheet"
			/>
		</head>

		<div class="header">
			<img src="../../../assets/img/header_img.png" />
		</div>
		<div class="_wrapper">
			<a href="" class="logo">
				<img src="../../../assets/img/logo_dl.png" />
			</a>
			<div v-if="isMobile()" class="_mobile">
				<div class="item">
					<h2>Bạn chưa cài đặt ứng dụng?</h2>
					<a href="#" class="btn btn-primary btn-large" @click="downloadApp()"
						><span>Tải ngay carezone</span></a
					>
				</div>
				<div class="item">
					<h3>Bạn đã có ứng dụng?</h3>
					<a href="" class="btn btn-gradient" @click="openApp()"
						><span>Mở ứng dụng</span><i class="arrow"></i
					></a>
				</div>
			</div>
			<div v-if="!isMobile()" class="_desktop">
				<h2>Bạn cần cài đặt ứng dụng Carezone trên điện thoại để truy cập vào link này.</h2>
			</div>
		</div>

		<div v-if="false" class="bg-gradient-info p-2 shadow-sm">
			<CIcon
				class="c-sidebar-brand-full"
				:src="logo"
				size="custom-size"
				:height="40"
				:wid="40"
				view-box="0 0 556 134"
			/>
		</div>

		<div v-if="false" class="d-flex flex-column align-items-center">
			<p v-if="isMobile()" class="mt-3 font-weight-bold">Bạn chưa cài đặt ứng dụng?</p>
			<div v-if="isMobile()" class="deep-link justify-content-center mb-3">
				<CButton class="p-2" color="info" @click="downloadApp()"> Tải ngay Carezone</CButton>
			</div>

			<p v-if="isMobile() && !isDownLoadType()" class="ml-3 mr-3 text-wrap text-center"
				>Nếu ứng trình duyệt của bạn không tự mở ứng dụng. Vui lòng nhấn nút dưới đây</p
			>
			<div v-if="isMobile() && !isDownLoadType()">
				<CButton class="p-2" color="info" @click="openApp()"> Mở ứng dụng</CButton>
			</div>

			<div v-if="!isMobile()" class="deep-link text-wrap text-center mt-5">
				<p>Bạn cần cài đặt ứng dụng Carezone trên điện thoại để truy cập vào link này</p>
			</div>
		</div>
	</div>
</template>

<script>
import {
	CHANGE_TOPIC_CATEGORY,
	GET_LIST_TOPIC_COMMENT,
	TRASH_LIST_TOPIC_COMMENT,
} from "../store/action-types"
import { RESET_FILTERS_TOPIC_LIST } from "../store/mutation-types"
import { createNamespacedHelpers, mapState } from "vuex"

const { mapActions, mapMutations } = createNamespacedHelpers("topics")
import { get } from "lodash-es"
import logo from "@/assets/img/carezone-white.png"

export default {
	name: "TopicDeepLink",

	components: {},

	props: {
		type: {
			type: [String, Number],
			default: null,
		},
		id: {
			type: [String, Number],
			default: null,
		},
	},

	data() {
		return {
			OpenIndicator: {
				render: createElement => createElement("span", { class: { toggle: false } }),
			},
			logo: logo,
			defaultGooglePlayUrl: "https://play.google.com/store/apps/details?id=co.path.app",
			defaultAppStoreUrl: "https://apps.apple.com/vn/app/carezone-we-care-4-love/id1564411665?l=vi",
			googlePlayUrl: process.env.VUE_APP_PLAY_STORE_URL,
			appStoreUrl: process.env.VUE_APP_APP_STORE_URL,
		}
	},

	computed: {
		...mapState("topics", ["topics", "totalItems", "totalPages", "recentFilters"]),
		...mapState("authentication", ["currentUser"]),
	},

	watch: {},

	created() {},

	beforeDestroy() {
		if (!this.$route.path.includes("topics")) this.RESET_FILTERS_TOPIC_LIST()
	},

	mounted: function () {
		this.openApp()
	},

	methods: {
		get,
		...mapActions({ GET_LIST_TOPIC_COMMENT, TRASH_LIST_TOPIC_COMMENT, CHANGE_TOPIC_CATEGORY }),
		...mapMutations({ RESET_FILTERS_TOPIC_LIST }),
		isDownLoadType() {
			if (this.type === "download") {
				return true
			}
			return false
		},
		isMobile: function () {
			var check = false
			;(function (a) {
				if (
					/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
						a,
					) ||
					/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
						a.substr(0, 4),
					)
				)
					check = true
			})(navigator.userAgent || navigator.vendor || window.opera)
			return check
		},
		openApp: function () {
			if (this.isMobile()) {
				let link = "carezone://?" + "type=" + this.type
				if (this.id != null) {
					link += "&id=" + this.id
				}
				window.location = link
			}
		},
		downloadApp: function () {
			if (this._isIOS()) {
				window.location = this.appStoreUrl ? this.appStoreUrl : this.defaultAppStoreUrl
			} else {
				window.location = this.googlePlayUrl ? this.googlePlayUrl : this.defaultGooglePlayUrl
			}
		},
		_isIOS: function () {
			return (
				["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(
					navigator.platform,
				) ||
				(navigator.userAgent.includes("Mac") && "ontouchend" in document)
			)
		},
	},
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";
@import "@/assets/scss/mixins";
.status {
	&--active {
		color: $color-sea-green;
	}

	&--inactive {
		color: $color-red;
	}
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
.justify-content-center {
	min-height: 100vh !important;
	background: linear-gradient(#2c32bb 0%, #3b5dc7 75%, #61c9e4 100%) !important;
	font-family: "Montserrat", sans-serif;
	color: #fff;
	font-weight: 400;
}
img {
	max-width: 100%;
	height: auto;
}
a {
	color: inherit;
}
.header {
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	z-index: 1;
}
.header img {
	width: 40vw;
	vertical-align: top;
	max-width: 400px;
}
._wrapper {
	min-height: 100vh;
	background-image: url("../../../assets/img/bg.png");
	background-repeat: no-repeat;
	background-position: bottom center;
	position: relative;
	display: flex;
	align-items: center;
	flex-direction: column;
	padding-top: 85px;
}
._wrapper .logo {
	width: 10em;
	height: 10em;
	display: flex;
	align-items: center;
	background: #fff;
	border-radius: 1.5625em;
	padding: 0.5em;
	margin-top: 25px;
	margin-bottom: 60px;
}
._wrapper .item {
	text-align: center;
}
.btn {
	display: inline-block;
	font-size: 1em;
	padding: 1em 30px;
	border-radius: 2em;
	border: 1px solid transparent;
	text-decoration: none;
	text-transform: uppercase;
	font-weight: 700;
}
.btn-large {
	font-size: 1.2em;
	padding-top: 0.5em;
	padding-bottom: 0.5em;
}
.btn-primary {
	background-color: #e62a7b;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.8);
}
.btn-gradient {
	position: relative;
	background: url("../../../assets/img/btn_bg.png") center no-repeat;
	background-size: 100% auto;
	width: 240px;
	padding-top: 0.6em;
	padding-bottom: 0.6em;
}
/*
.btn-gradient:before,
.btn-gradient:after{
	content: '';
	position: absolute;
	inset:0;
	background: #477ed0;
	border-radius: inherit;
	
}
.btn-gradient:before{
	background: linear-gradient(-135deg,#83c4f4 0%,#006cc3 10%, #001845 50%, #7bc2f5 90%, #fff 100%);
}
.btn-gradient:after{
	inset: 3px;
}
*/
.btn-gradient span {
	position: relative;
	z-index: 1;
}
._wrapper .item {
	margin-bottom: 40px;
}
._wrapper .item h2 {
	font-size: 18px;
	margin-bottom: 20px;
}
._wrapper .item h3 {
	font-size: 16px;
	margin-bottom: 10px;
}
.btn .arrow {
	position: absolute;
	right: 20px;
	top: 50%;
	transform: translateY(-50%);
	background: url("../../../assets/img/arrow.png") no-repeat center;
	width: 7px;
	height: 23px;
	background-size: 100%;
}
._wrapper .img_bottom {
	max-height: 40vh;
	width: auto;
}
._desktop h2 {
	font-size: 18px;
}
body.mobile ._mobile {
	display: block;
}
body.desktop ._desktop {
	display: block;
}
@media (max-width: 640px) {
	._wrapper {
		background: url("../../../assets/img/mobile_bg.jpg") no-repeat bottom center;
		background-size: cover;
		min-height: 178vw;
	}
	._wrapper .logo {
		font-size: 8px;
		margin-top: 15px;
		margin-bottom: 40px;
	}
	._wrapper {
		padding-top: 60px;
	}
}
</style>
